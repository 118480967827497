<template>
  <div class="md-layout">
    <CSLoading :is-loading="isLoading"></CSLoading>
    <div class="md-layout-item md-size-100 product-list">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <span class="material-symbols-outlined">package_2</span>
          </div>
          <h4 class="title">{{ $t("products.products") }}</h4>
          <md-button class="md-icon-button md-success add-button" @click="showCreateModal">
            <md-icon>add</md-icon>
          </md-button>
        </md-card-header>
        <md-card-content>
          <div class="md-layout">
            <div class="md-layout-item md-size-25">
              <md-field md-clearable>
                <label>{{ $t("products.keywords") }}</label>
                <md-input v-model="filters.keywords" @input="debounceApplyFilters"></md-input>
              </md-field>
            </div>
            <div class="md-layout md-layout-item md-size-25">
              <md-checkbox v-model="enableBulky">{{ $t("products.bulky") }}</md-checkbox>
              <md-switch v-model="filterBulky" :disabled="!enableBulky" />
            </div>
          </div>
          <v-server-table :columns="tableColumns" :options="tableOptions" ref="table" class="table-products" id="table-products">
            <template #h__hs>
              <div class="head-hs">{{ $t("products.hsCode") }}</div>
            </template>
            <template #actions="{ row }"
              ><span @click="showEditModal(row)"><md-icon>edit</md-icon></span></template
            >
            <template #name="{ row }">
              <div class="cell-name">
                {{ row.name && row.name.substring(0, 50) }}
              </div>
            </template>
            <template #shippingSizeLength="{ row }">
              <div class="cell-shipping-size-length">{{ row.shippingSizeLength | formatNumberGerman(0) }} cm</div>
            </template>
            <template #shippingSizeWidth="{ row }">
              <div class="cell-shipping-size-width">{{ row.shippingSizeWidth | formatNumberGerman }} cm</div>
            </template>
            <template #shippingSizeHeight="{ row }">
              <div class="cell-shipping-size-height">{{ row.shippingSizeHeight | formatNumberGerman }} cm</div>
            </template>
            <template #shippingSizeWeight="{ row }">
              <div class="cell-shipping-size-weight">{{ row.shippingSizeWeight | formatNumberGerman }} kg</div>
            </template>
            <template #hs="{ row }">
              <div class="cell-hs">
                <div class="text-nowrap">UK: {{ row.hsUK || 0 }}</div>
                <div class="text-nowrap">EU: {{ row.hsEU || 0 }}</div>
                <div class="text-nowrap">CH: {{ row.hsCH || 0 }}</div>
              </div>
            </template>
            <template #bulky="{ row }">
              <span v-if="row.bulky === 'true'" class="text-success material-symbols-outlined">done</span>
              <span v-else-if="row.bulky === 'false'" class="text-danger material-symbols-outlined">close</span>
            </template>
            <template #supplier="{ row }">
              <div class="cell-supplier">
                {{ row.supplier && row.supplier.name }}
              </div>
            </template>
          </v-server-table>
        </md-card-content>
      </md-card>
    </div>
    <modal v-if="createModal" @close="hideCreateModal" class-name="shipping-plan-modal-container">
      <template slot="header">
        <h4 class="modal-title">{{ $t("products.createProduct") }}</h4>
        <md-button class="md-simple md-just-icon md-round modal-default-button" @click="hideCreateModal">
          <md-icon>clear</md-icon>
        </md-button>
      </template>

      <template slot="body">
        <ValidationObserver v-slot="{}" ref="createForm">
          <ValidationProvider name="sku" rules="required" v-slot="{ passed, failed, errors }">
            <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
              <label>{{ $t("products.sku") }}</label>
              <md-input v-model="sku" type="text"> </md-input>
              <div class="md-error" v-if="errors[0]">
                {{ $t(errors[0]) }}
              </div>
            </md-field>
          </ValidationProvider>
          <ValidationProvider name="name" rules="required" v-slot="{ passed, failed, errors }">
            <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
              <label>{{ $t("products.name") }}</label>
              <md-input v-model="name" type="text"> </md-input>
              <div class="md-error" v-if="errors[0]">
                {{ $t(errors[0]) }}
              </div>
            </md-field>
          </ValidationProvider>

          <div class="md-layout md-gutter">
            <!-- <div class="md-layout-item md-layout md-gutter"> -->
            <div class="md-layout-item md-large-size-50 md-medium-size-100">
              <md-field>
                <label>{{ $t("products.shippingSizeLength") }}</label>
                <md-input v-model="shippingSizeLength" type="number"> </md-input>
                <span class="md-suffix">cm</span>
              </md-field>
            </div>
            <div class="md-layout-item md-large-size-50 md-medium-size-100">
              <md-field>
                <label>{{ $t("products.shippingSizeWidth") }}</label>
                <md-input v-model="shippingSizeWidth" type="number"> </md-input>
                <span class="md-suffix">cm</span>
              </md-field>
            </div>
            <div class="md-layout-item md-large-size-50 md-medium-size-100">
              <md-field>
                <label>{{ $t("products.shippingSizeHeight") }}</label>
                <md-input v-model="shippingSizeHeight" type="number"> </md-input>
                <span class="md-suffix">cm</span>
              </md-field>
            </div>
            <div class="md-layout-item md-large-size-50 md-medium-size-100">
              <md-field>
                <label>{{ $t("products.shippingSizeWeight") }}</label>
                <md-input v-model="shippingSizeWeight" type="number"> </md-input>
                <span class="md-suffix">kg</span>
              </md-field>
            </div>
            <!-- </div> -->
          </div>

          <div style="width: 30%">
            <md-switch v-model="bulky" value="true" class="md-primary">{{ $t("products.bulky") }}</md-switch>
          </div>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-large-size-50 md-medium-size-100">
              <ValidationProvider name="hsUK" v-slot="{ passed, failed, errors }">
                <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
                  <label>{{ $t("products.hsUK") }}</label>
                  <md-input v-model="hsUK" type="number"> </md-input>
                  <div class="md-error" v-if="errors[0]">
                    {{ $t(errors[0]) }}
                  </div>
                </md-field>
              </ValidationProvider>
              <ValidationProvider name="hsEU" v-slot="{ passed, failed, errors }">
                <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
                  <label>{{ $t("products.hsEU") }}</label>
                  <md-input v-model="hsEU" type="number"> </md-input>
                  <div class="md-error" v-if="errors[0]">
                    {{ $t(errors[0]) }}
                  </div>
                </md-field>
              </ValidationProvider>
              <ValidationProvider name="hsCH" v-slot="{ passed, failed, errors }">
                <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
                  <label>{{ $t("products.hsCH") }}</label>
                  <md-input v-model="hsCH" type="number"> </md-input>
                  <div class="md-error" v-if="errors[0]">
                    {{ $t(errors[0]) }}
                  </div>
                </md-field>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-large-size-50 md-medium-size-100">
              <ValidationProvider name="outboundCarrier" rules="required" v-slot="{ errors }">
                <md-field>
                  <label>{{ $t("products.outboundCarrier") }}</label>
                  <md-select v-model="outboundCarrier" name="outboundCarrier" id="outboundCarrier">
                    <md-option :key="i.id" v-for="i in carrierOptions" :value="i.id">{{ i.name }}</md-option>
                  </md-select>
                  <div class="md-error" v-if="errors[0]">
                    {{ $t(errors[0]) }}
                  </div>
                </md-field>
              </ValidationProvider>
              <ValidationProvider name="carrierUK" rules="required" v-slot="{ errors }">
                <md-field>
                  <label>{{ $t("products.carrierUK") }}</label>
                  <md-select v-model="carrierUK" name="carrierUK" id="carrierUK">
                    <md-option :key="i.id" v-for="i in carrierOptions" :value="i.id">{{ i.name }}</md-option>
                  </md-select>
                  <div class="md-error" v-if="errors[0]">
                    {{ $t(errors[0]) }}
                  </div>
                </md-field>
              </ValidationProvider>
              <ValidationProvider name="carrierEU" rules="required" v-slot="{ errors }">
                <md-field>
                  <label>{{ $t("products.carrierEU") }}</label>
                  <md-select v-model="carrierEU" name="carrierEU" id="carrierEU">
                    <md-option :key="i.id" v-for="i in carrierOptions" :value="i.id">{{ i.name }}</md-option>
                  </md-select>
                  <div class="md-error" v-if="errors[0]">
                    {{ $t(errors[0]) }}
                  </div>
                </md-field>
              </ValidationProvider>
            </div>
          </div>

          <ValidationProvider name="supplierId" rules="required" v-slot="{ errors }">
            <div class="wrap-v-select" style="text-align: left; margin-top: 15px">
              <v-select label="name" v-model="supplierId" :options="supplierNameOptions" :placeholder="$t('products.supplier')"> </v-select>
              <div class="md-error" v-if="errors[0]">
                {{ $t(errors[0]) }}
              </div>
            </div>
          </ValidationProvider>
        </ValidationObserver>
      </template>

      <template slot="footer">
        <md-button class="md-secondary mr-1" @click="hideCreateModal">{{ $t("products.close") }}</md-button>
        <md-button class="md-success" @click="createProduct">{{ $t("products.save") }}</md-button>
      </template>
    </modal>

    <modal v-if="editModal" @close="hideEditModal" class-name="shipping-plan-modal-container">
      <template slot="header">
        <h4 class="modal-title">Edit Product</h4>
        <md-button class="md-simple md-just-icon md-round modal-default-button" @click="hideEditModal">
          <md-icon>clear</md-icon>
        </md-button>
      </template>

      <template slot="body">
        <ValidationObserver v-slot="{}" ref="editForm">
          <ValidationProvider name="sku" rules="required" v-slot="{ passed, failed, errors }">
            <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
              <label>{{ $t("products.sku") }}</label>
              <md-input v-model="sku" type="text" disabled> </md-input>
              <div class="md-error" v-if="errors[0]">
                {{ $t(errors[0]) }}
              </div>
            </md-field>
          </ValidationProvider>
          <ValidationProvider name="name" rules="required" v-slot="{ passed, failed, errors }">
            <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
              <label>{{ $t("products.name") }}</label>
              <md-input v-model="name" type="text"> </md-input>
              <div class="md-error" v-if="errors[0]">
                {{ $t(errors[0]) }}
              </div>
            </md-field>
          </ValidationProvider>
          <md-field>
            <label>{{ $t("products.shippingSizeLength") }}</label>
            <md-input v-model="shippingSizeLength" type="number"> </md-input>
            <span class="md-suffix">cm</span>
          </md-field>
          <md-field>
            <label>{{ $t("products.shippingSizeWidth") }}</label>
            <md-input v-model="shippingSizeWidth" type="number"> </md-input>
            <span class="md-suffix">cm</span>
          </md-field>
          <md-field>
            <label>{{ $t("products.shippingSizeHeight") }}</label>
            <md-input v-model="shippingSizeHeight" type="number"> </md-input>
            <span class="md-suffix">cm</span>
          </md-field>
          <md-field>
            <label>{{ $t("products.shippingSizeWeight") }}</label>
            <md-input v-model="shippingSizeWeight" type="number"> </md-input>
            <span class="md-suffix">kg</span>
          </md-field>
          <div style="width: 30%">
            <md-switch v-model="bulky" value="true" class="md-primary">{{ $t("products.bulky") }}</md-switch>
          </div>
          <ValidationProvider name="hsUK" rules="max_value:9999999999" v-slot="{ passed, failed, errors }">
            <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
              <label>{{ $t("products.hsUK") }}</label>
              <md-input v-model="hsUK" type="number"> </md-input>
              <div class="md-error" v-if="errors[0]">
                {{ $t(errors[0]) }}
              </div>
            </md-field>
          </ValidationProvider>
          <ValidationProvider name="hsEU" rules="max_value:9999999999" v-slot="{ passed, failed, errors }">
            <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
              <label>{{ $t("products.hsEU") }}</label>
              <md-input v-model="hsEU" type="number"> </md-input>
              <div class="md-error" v-if="errors[0]">
                {{ $t(errors[0]) }}
              </div>
            </md-field>
          </ValidationProvider>
          <ValidationProvider name="hsCH" rules="max_value:9999999999" v-slot="{ passed, failed, errors }">
            <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
              <label>{{ $t("products.hsCH") }}</label>
              <md-input v-model="hsCH" type="number"> </md-input>
              <div class="md-error" v-if="errors[0]">
                {{ $t(errors[0]) }}
              </div>
            </md-field>
          </ValidationProvider>
          <ValidationProvider name="outboundCarrier" rules="required" v-slot="{ errors }">
            <md-field>
              <label>{{ $t("products.outboundCarrier") }}</label>
              <md-select v-model="outboundCarrier" name="outboundCarrier" id="outboundCarrier">
                <md-option :key="i.id" v-for="i in carrierOptions" :value="i.id">{{ i.name }}</md-option>
              </md-select>
              <div class="md-error" v-if="errors[0]">
                {{ $t(errors[0]) }}
              </div>
            </md-field>
          </ValidationProvider>
          <ValidationProvider name="carrierUK" rules="required" v-slot="{ errors }">
            <md-field>
              <label>{{ $t("products.carrierUK") }}</label>
              <md-select v-model="carrierUK" name="carrierUK" id="carrierUK">
                <md-option :key="i.id" v-for="i in carrierOptions" :value="i.id">{{ i.name }}</md-option>
              </md-select>
              <div class="md-error" v-if="errors[0]">
                {{ $t(errors[0]) }}
              </div>
            </md-field>
          </ValidationProvider>
          <ValidationProvider name="carrierEU" rules="required" v-slot="{ errors }">
            <md-field>
              <label>{{ $t("products.carrierEU") }}</label>
              <md-select v-model="carrierEU" name="carrierEU" id="carrierEU">
                <md-option :key="i.id" v-for="i in carrierOptions" :value="i.id">{{ i.name }}</md-option>
              </md-select>
              <div class="md-error" v-if="errors[0]">
                {{ $t(errors[0]) }}
              </div>
            </md-field>
          </ValidationProvider>
          <ValidationProvider name="supplierId" rules="required" v-slot="{ errors }">
            <div class="wrap-v-select" style="text-align: left; margin-top: 15px">
              <v-select label="name" v-model="supplierId" :options="supplierNameOptions" :placeholder="$t('products.supplier')"> </v-select>
              <div class="md-error" v-if="errors[0]">
                {{ $t(errors[0]) }}
              </div>
            </div>
          </ValidationProvider>
        </ValidationObserver>
      </template>

      <template slot="footer">
        <md-button class="md-secondary mr-1" @click="hideEditModal">{{ $t("products.close") }}</md-button>
        <md-button class="md-success" @click="editProduct">{{ $t("products.save") }}</md-button>
      </template>
    </modal>
  </div>
</template>
<script>
import { debounce } from "lodash";
import { Modal } from "@/components";
import axiosFactory from "../../services/axios.factory";
import CSLoading from "../../components/Loading/CSLoading.vue";
import { EventBus } from "@/helpers/event-bus";
import { parse } from "date-fns";

export default {
  components: {
    Modal,
    CSLoading,
  },
  data() {
    return {
      isLoading: true,
      createModal: false,
      editModal: false,
      sku: null,
      name: null,
      inStock: null,
      inflow: null,
      shippingSizeLength: null,
      shippingSizeWidth: null,
      shippingSizeHeight: null,
      shippingSizeWeight: null,
      hsUK: null,
      hsEU: null,
      hsCH: null,
      outboundCarrier: null,
      carrierUK: null,
      carrierEU: null,
      bulky: "",
      supplierId: null,
      id: null,
      enableBulky: false,
      filterBulky: false,
      filters: {
        keywords: "",
        bulky: "all",
      },
      tableColumns: [
        "actions",
        "sku",
        "name",
        "inStock",
        "inFlow",
        "shippingSizeLength",
        "shippingSizeWidth",
        "shippingSizeHeight",
        "shippingSizeWeight",
        "bulky",
        "hs",
        "outboundCarrier",
        "carrierUK",
        "carrierEU",
        "supplier",
      ],
      tableOptions: {
        headings: {
          actions: () => this.$t("products.table.actions"),
          sku: () => this.$t("products.table.sku"),
          name: () => this.$t("products.table.name"),
          inStock: () => this.$t("products.table.inStock"),
          inFlow: () => this.$t("products.table.inFlow"),
          shippingSizeLength: () => this.$t("products.table.shippingSizeLength"),
          shippingSizeWidth: () => this.$t("products.table.shippingSizeWidth"),
          shippingSizeHeight: () => this.$t("products.table.shippingSizeHeight"),
          shippingSizeWeight: () => this.$t("products.table.shippingSizeWeight"),
          outboundCarrier: () => this.$t("products.table.outboundCarrier"),
          carrierUK: () => this.$t("products.table.carrierUK"),
          carrierEU: () => this.$t("products.table.carrierEU"),
          bulky: () => this.$t("products.table.bulky"),
          supplier: () => this.$t("products.table.supplier"),
        },
        perPage: 25,
        perPageValues: [25, 100, 5000],
        filterable: false,
        sortIcon: {
          is: "fa-sort",
          base: "fa",
          up: "fa-sort-up",
          down: "fa-sort-down",
        },
        sortable: ["sku", "name", "shippingSizeLength", "shippingSizeWidth", "shippingSizeHeight", "shippingSizeWeight", "outboundCarrier"],
        params: {},
        resizableColumns: false,
        requestFunction: this.requestFunction,
        responseAdapter: this.responseFunction,
        rowClassCallback: this.rowClassCallback,
        pagination: {
          show: true,
        },
        texts: {
          count: this.$t("table.count"),
          limit: this.$t("table.records"),
          noResults: this.$t("table.noRecord"),
        },
      },
      tableData: [],
      totalItems: 0,
      table: null,
    };
  },
  computed: {
    supplierNameOptions() {
      return this.$store.state.shippingData.supplierNames.map((s) => ({ value: s.id, name: s.name })) || [];
    },
    carrierOptions() {
      return this.$store.state.shippingData.carriers || [];
    },
  },
  watch: {
    enableBulky(bool) {
      if (!bool) {
        this.filters.bulky = "all";
      } else {
        if (this.filterBulky) {
          this.filters.bulky = "true";
        } else {
          this.filters.bulky = "false";
        }
      }

      this.debounceApplyFilters();
    },
    filterBulky(bool) {
      if (bool) {
        this.filters.bulky = "true";
      } else {
        this.filters.bulky = "false";
      }

      this.debounceApplyFilters();
    },
  },
  methods: {
    hideCreateModal() {
      this.createModal = false;
      this.resetForm();
    },
    hideEditModal() {
      this.editModal = false;
      this.resetForm();
    },
    resetForm() {
      this.hsUK = null;
      this.hsEU = null;
      this.hsCH = null;
    },
    showCreateModal(product = {}) {
      this.sku = product.sku;
      this.name = product.name;
      this.shippingSizeLength = product.shippingSizeLength;
      this.shippingSizeWidth = product.shippingSizeWidth;
      this.shippingSizeHeight = product.shippingSizeHeight;
      this.shippingSizeWeight = product.shippingSizeWeight;
      this.outboundCarrier = product.outboundCarrier;
      this.carrierUK = product.carrierUK;
      this.carrierEU = product.carrierEU;
      this.bulky = "";
      this.createModal = true;
    },
    showEditModal(product) {
      this.sku = product.sku;
      this.name = product.name;
      this.inStock = product.inStock;
      this.inflow = product.inflow;
      this.shippingSizeLength = product.shippingSizeLength;
      this.shippingSizeWidth = product.shippingSizeWidth;
      this.shippingSizeHeight = product.shippingSizeHeight;
      this.shippingSizeWeight = product.shippingSizeWeight;
      this.hsUK = product.hsUK;
      this.hsEU = product.hsEU;
      this.hsCH = product.hsCH;
      let carrier = this.carrierOptions.find((i) => i.name == product.outboundCarrier);
      this.outboundCarrier = carrier && carrier.id;
      carrier = this.carrierOptions.find((i) => i.name == product.carrierUK);
      this.carrierUK = carrier && carrier.id;
      carrier = this.carrierOptions.find((i) => i.name == product.carrierEU);
      this.carrierEU = carrier && carrier.id;
      var supplier = this.supplierNameOptions.find((i) => i.value == product.supplierId);
      this.supplierId = supplier;
      this.bulky = product.bulky;
      this.id = product.id;
      this.editModal = true;
    },
    createProduct() {
      this.$refs.createForm.validate().then((success) => {
        if (!success) {
          return;
        }

        this.isLoading = true;
        let carrier = this.carrierOptions.find((i) => i.id === this.outboundCarrier);
        let carrierUK = this.carrierOptions.find((i) => i.id === this.carrierUK);
        let carrierEU = this.carrierOptions.find((i) => i.id === this.carrierEU);
        const payload = {
          sku: this.sku,
          name: this.name,
          shippingSizeLength: this.shippingSizeLength ? parseFloat(this.shippingSizeLength) : 0,
          shippingSizeWidth: this.shippingSizeWidth ? parseFloat(this.shippingSizeWidth) : 0,
          shippingSizeHeight: this.shippingSizeHeight ? parseFloat(this.shippingSizeHeight) : 0,
          shippingSizeWeight: this.shippingSizeWeight ? parseFloat(this.shippingSizeWeight) : 0,
          hsUK: this.hsUK,
          hsEU: this.hsEU,
          hsCH: this.hsCH,
          outboundCarrier: carrier && carrier.name,
          carrierUK: carrierUK && carrierUK.name,
          carrierEU: carrierEU && carrierEU.name,
          bulky: this.bulky ? this.bulky : "false",
          supplierId: this.supplierId.value,
        };
        // console.log("create product", payload);
        this.$store
          .dispatch("shippingData/createProduct", payload)
          .then(() => {
            this.$notify({
              timeout: 2500,
              message: "The product is created",
              icon: "add_alert",
              horizontalAlign: "right",
              verticalAlign: "top",
              type: "success",
            });

            this.applyFilters({});
          })
          .then(() => {
            this.hideCreateModal();
          })
          .catch(() => {
            this.$notify({
              timeout: 2500,
              message: "The product sku is duplicated",
              icon: "add_alert",
              horizontalAlign: "right",
              verticalAlign: "top",
              type: "error",
            });
          })
          .finally(() => {
            this.isLoading = false;
          });
      });
    },
    editProduct() {
      this.$refs.editForm.validate().then((success) => {
        if (!success) {
          return;
        }

        debugger;
        this.isLoading = true;
        let carrier = this.carrierOptions.find((i) => i.id === this.outboundCarrier);
        let carrierUK = this.carrierOptions.find((i) => i.id === this.carrierUK);
        let carrierEU = this.carrierOptions.find((i) => i.id === this.carrierEU);
        const payload = {
          sku: this.sku,
          name: this.name,
          shippingSizeLength: this.shippingSizeLength ? parseFloat(this.shippingSizeLength) : 0,
          shippingSizeWidth: this.shippingSizeWidth ? parseFloat(this.shippingSizeWidth) : 0,
          shippingSizeHeight: this.shippingSizeHeight ? parseFloat(this.shippingSizeHeight) : 0,
          shippingSizeWeight: this.shippingSizeWeight ? parseFloat(this.shippingSizeWeight) : 0,
          hsUK: this.hsUK,
          hsEU: this.hsEU,
          hsCH: this.hsCH,
          outboundCarrier: carrier && carrier.name,
          carrierUK: carrierUK && carrierUK.name,
          carrierEU: carrierEU && carrierEU.name,
          bulky: this.bulky ? this.bulky : "false",
          supplierId: this.supplierId.value,
        };
        // console.log("edit product", payload);
        axiosFactory()
          .put("shipping_data/products/" + this.id, payload)
          .then(() => {
            this.$notify({
              timeout: 2500,
              message: "The product is updated",
              icon: "add_alert",
              horizontalAlign: "right",
              verticalAlign: "top",
              type: "success",
            });

            this.applyFilters({});
          })
          .then(() => {
            this.hideEditModal();
          })
          .finally(() => {
            this.isLoading = false;
          });
      });
    },
    requestFunction(data) {
      const { page, limit, orderBy, ascending, ...filters } = data;
      this.currentPage = page;
      let params = {
        pageIndex: this.currentPage,
        pageSize: limit,
        orderBy,
        ascending,
        ...filters,
      };

      this.isLoading = true;
      return axiosFactory()
        .get(`/shipping_data/products`, { params })
        .catch(function (e) {})
        .finally(() => {
          this.isLoading = false;
        });
    },
    responseFunction({ data }) {
      this.tableData = data.data.map((i) => ({ ...i, selected: false }));
      this.totalItems = data.total;

      return {
        data: this.tableData,
        count: data.total,
      };
    },
    rowClassCallback(row) {
      return "";
    },
    debounceApplyFilters: debounce(function () {
      this.applyFilters({});
    }, 300),
    applyFilters(filters) {
      this.$refs.table.options.params = {
        ...this.$refs.table.options.params,
        ...this.filters,
        ...filters,
        pageIndex: 0,
      };
      this.currentPage = 1;
      this.$refs.table.setPage(this.currentPage);
    },
  },
  updated() {
    EventBus.$emit("scroll:changed");
  },
  created() {
    this.$store.dispatch("shippingData/getSupplierNames");
    this.$store.dispatch("shippingData/getCarriers");
  },
};
</script>
<style scoped>
.table-transparent {
  background-color: transparent !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mr-1 {
  margin-right: 15px;
}

.md-card .md-card-header.md-card-header-icon .material-symbols-outlined {
  width: 33px;
  height: 33px;
  text-align: center;
  line-height: 33px;
  color: #fff;
  margin: 0 !important;
  font-size: 28px !important;
}
.switch-toggle-label {
  font-weight: normal !important;
}
</style>
