// =========================================================
// * Vue Material Dashboard PRO - v1.5.2
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vue-material-dashboard-pro
// * Copyright 2022 Creative Tim (https://www.creative-tim.com)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import Vue from "vue";
import { ValidationObserver, ValidationProvider, extend, configure } from "vee-validate";
import * as rules from "vee-validate/dist/rules";
import numeral from "numeral";
import { format, parseISO } from "date-fns";
import { ClientTable, ServerTable } from "vue-tables-2-premium";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

import DashboardPlugin from "./material-dashboard";

// Plugins
import App from "./App.vue";
import Chartist from "chartist";

// store setup
import store from "./store";

import i18n from "./i18n";

// router setup
import router from "./routes/routes";

// plugin setup
Vue.use(DashboardPlugin);
// global library setup
Vue.prototype.$Chartist = Chartist;

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
configure({
  defaultMessage: (field, values) => {
    values._field_ = i18n.t(`fields.${field}`);
    return i18n.t(`validation.${values._rule_}`, values);
  },
});
Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule]);
});

Vue.filter("formatDate", (value) => {
  const formatStyle = i18n.locale === "de" ? "dd.MM.yyyy" : "MM-dd-yyyy";
  return value && format(parseISO(value), formatStyle);
});
Vue.filter("formatDateTime", (value) => {
  const formatStyle = i18n.locale === "de" ? "dd.MM.yyyy HH:mm" : "MM-dd-yyyy HH:mm";
  return value && format(parseISO(value), formatStyle);
});
Vue.filter("formatTime", (value) => {
  return value && format(parseISO(value), "HH:mm");
});
Vue.filter("formatMonthYear", (value) => {
  return value && format(parseISO(value), "MMMM yyyy");
});
Vue.filter("formatDayOfWeek", (value) => {
  return value && format(parseISO(value), "EE");
});

numeral.register("locale", "de", {
  delimiters: {
    thousands: ".",
    decimal: ",",
  },
  currency: {
    symbol: "$",
  },
});
numeral.locale(i18n.locale);

Vue.filter("formatNumber", (value) => {
  if (!value && value !== 0) return "";
  numeral.locale(i18n.locale);
  return numeral(value).format("0.00");
});
Vue.filter("formatAmount", (value) => {
  if (!value && value !== 0) return "";
  numeral.locale(i18n.locale);
  return numeral(value).format("0,0.00$");
});
Vue.filter("formatNumberGerman", (value, decimals = 2) => {
  if (!value && value !== 0) return "";
  numeral.locale("de");
  return numeral(value).format(`0,0.[${"0".repeat(decimals)}]`);
});
Vue.filter("capitalized", (name) => {
  const capitalizedFirst = name[0].toUpperCase();
  const rest = name.slice(1);

  return capitalizedFirst + rest;
});
Vue.filter("uppercase", (name) => {
  if (!name) return "";

  return name.toUpperCase();
});

Vue.use(ClientTable, {}, false, "bootstrap4");
Vue.use(ServerTable, {}, false, "bootstrap4");
Vue.component("v-select", vSelect);

/* eslint-disable no-new */
window.VueSingleton = new Vue({
  el: "#app",
  store,
  i18n,
  router,

  render: (h) => h(App),
});
