<template>
  <md-card>
    <md-card-header class="md-card-header-icon md-card-header-green">
      <!-- <div class="card-icon">
    <md-icon>newspaper</md-icon>
    </div> -->
      <h4 class="title">{{ $t("news.news") }}</h4>
      <md-button v-if="hasPermission('Write', 'News', '')" @click="showCreateModal" class="md-icon-button md-success add-button md-sm">
        <md-icon>add</md-icon>
      </md-button>
    </md-card-header>
    <md-card-content>
      <div :class="['md-collapse', activeCollapse(index + 1), { [getColorCollapse('success')]: true }]" v-for="(item, index) in listPosts" :key="item.id">
        <div class="md-collapse-label" @click="toggle(index + 1)">
          <h5 class="md-collapse-title">
            {{ item.title }}
            <md-icon>keyboard_arrow_down</md-icon>
          </h5>
        </div>

        <collapse-transition>
          <div class="md-collapse-content" v-show="getActiveCollapse(index + 1)">
            <div>{{ item.content }}</div>
            <div class="post-footer">
              <span class="md-caption">{{ $t("news.createdAt") }}: {{ item.date | formatDateTime }}</span>

              <div class="md-actions">
                <button v-if="hasPermission('Write', 'News', '')" @click="showEditModal(item)" class="btn-action">
                  <md-icon>edit</md-icon>
                </button>
                <button
                  v-if="hasPermission('Write', 'News', '')"
                  @click="
                    deleteModal = true;
                    post = item;
                  "
                  class="btn-action"
                >
                  <md-icon>delete</md-icon>
                </button>
              </div>
            </div>
          </div>
        </collapse-transition>
      </div>
    </md-card-content>
    <modal v-if="editPostModal" @close="hideEditModal" class-name="modal-post">
      <template slot="header">
        <h4 class="modal-title">{{ $t("news.edit") }}</h4>
        <md-button class="md-simple md-just-icon md-round modal-default-button" @click="hideEditModal">
          <md-icon>clear</md-icon>
        </md-button>
      </template>
      <template slot="body">
        <FormPost :data="post" @post-updated="afterSaved" @cancel="hideEditModal" />
      </template>
    </modal>
    <modal v-if="createPostModal" @close="hideCreateModal" class-name="modal-post">
      <template slot="header">
        <h4 class="modal-title">{{ $t("news.create") }}</h4>
        <md-button class="md-simple md-just-icon md-round modal-default-button" @click="hideCreateModal">
          <md-icon>clear</md-icon>
        </md-button>
      </template>
      <template slot="body">
        <FormPost :data="post" @post-created="afterSaved" @cancel="hideCreateModal" />
      </template>
    </modal>
    <md-dialog :md-active.sync="deleteModal" :md-click-outside-to-close="false">
      <md-dialog-title> {{ $t("news.confirmDelete") }} </md-dialog-title>
      <md-dialog-content v-html="confirmDeleteMessage"> </md-dialog-content>

      <md-dialog-actions>
        <md-button class="md-secondary" @click="hideDeleteModal">{{ $t("news.cancel") }}</md-button>
        <md-button class="md-danger" @click="deleteNews">{{ $t("news.delete") }}</md-button>
      </md-dialog-actions>
    </md-dialog>
  </md-card>
</template>

<script>
import { CollapseTransition } from "vue2-transitions";
import { hasPermission } from "../../mixins/auth";
import { Modal } from "@/components";
import FormPost from "../News/Form.vue";

export default {
  components: {
    CollapseTransition,
    Modal,
    FormPost,
  },
  data() {
    return {
      isActive: 1,
      isLoading: false,
      editPostModal: false,
      createPostModal: false,
      deleteModal: false,
      posts: [],
      post: null,
    };
  },
  computed: {
    listPosts() {
      return this.$store.state.posts.posts || [];
    },
    confirmDeleteMessage() {
      return this.$t("news.confirmDeleteMessage", {
        headline: this.post?.title,
      });
    },
  },
  methods: {
    showCreateModal() {
      this.post = null;
      this.createPostModal = true;
    },
    hideCreateModal() {
      this.createPostModal = false;
      this.post = null;
    },
    showEditModal(post) {
      this.post = post;
      this.editPostModal = true;
    },
    hideEditModal() {
      this.editPostModal = false;
      this.post = null;
    },
    hideDeleteModal() {
      this.deleteModal = false;
      this.post = null;
    },
    async deleteNews() {
      this.deleteModal = false;
      try {
        await this.$store.dispatch("posts/delete", this.post.id);
        this.$notify({
          timeout: 2500,
          message: "The News is deleted successfully",
          icon: "add_alert",
          horizontalAlign: "right",
          verticalAlign: "top",
          type: "success",
        });

        return this.$store.dispatch("posts/getAll");
      } catch (error) {
        this.$notify({
          timeout: 2500,
          message: "The News is not deleted",
          icon: "add_alert",
          horizontalAlign: "right",
          verticalAlign: "top",
          type: "error",
        });
      }
    },
    afterSaved() {
      this.hideCreateModal();
      this.hideEditModal();

      this.$notify({
        timeout: 2500,
        message: "The News is saved successfully",
        icon: "add_alert",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: "success",
      });

      return this.$store.dispatch("posts/getAll");
    },
    hasPermission(permissionName, tableName, columnName) {
      return hasPermission(permissionName, tableName, columnName);
    },
    getActiveCollapse(index) {
      return this.isActive == index;
    },
    activeCollapse(index) {
      return {
        "is-active": this.isActive == index,
      };
    },
    toggle(index) {
      if (index == this.isActive) {
        this.isActive = 0;
      } else {
        this.isActive = index;
      }
    },
    getCollapseContent: function (index) {
      return "content-" + index + "";
    },
    getColorCollapse: function (colorCollapse) {
      return "md-" + colorCollapse + "";
    },
  },
  created() {
    Promise.all([this.$store.dispatch("posts/getAll")]).finally((_) => {
      this.isLoading = false;
    });
  },
};
</script>

<style scoped>
.post-footer {
  margin-top: 0.5rem;
  border-top: 1px dashed #ccc;
  padding: 0.5rem 0 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.md-actions {
  display: flex;
  justify-content: flex-stretch;
  align-items: center;
  margin-left: auto;
}
.btn-action {
  background-color: transparent;
  border: none;
  cursor: pointer;
}
.btn-action:hover .md-icon {
  color: var(--md-theme-default-accent);
}
.btn-action .md-icon {
  font-size: 1.25rem !important;
  width: 1rem;
  height: 1rem;
}
</style>
